import React from 'react';
import { Link } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import MainMenu from '../MainMenu';
import styled from 'styled-components';
import LOGO from '../../../Logo'

const SideDrawer = (props) => {
    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }

    return (
        <NavWrapper>

            <nav className={drawerClasses}>
                <Link to='/' className="side-logo">
                    <LOGO />
                </Link>
                {/* <div className="side-logo"><LOGO className="side-logo" /></div> */}
                <ul>
                    {MainMenu.map((menu) => (
                        <li key={menu.title}>
                            <Link to={menu.linkUrl}>
                                {menu.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </NavWrapper>

    );
};

const NavWrapper = styled.div`


.side-drawer {
    height: 100%;
    background: rgba(132, 36, 12,0.9);
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 9999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}
.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media (max-height: 400px) {
.side-drawer ul {
    justify-content: end;

}
}

.side-drawer li a {
    display: block;
  text-align: center;
  padding: 1rem 0;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--secondarycolor)
}

/* .side-drawer a:hover,
.side-drawer a:active {
    color: var(--mainColor);
} */

.side-drawer li a:hover,
.side-drawer li a:focus {
    background-color: var(--secondarycolor);
    color: var(--maincolor);
}

@media (min-width: 800px) {
    .side-drawer {
        display: none;
    }
}

.side-logo{
position: absolute;
left: 50%;
transform: translateX(-50%);
top: 2%;
color: white;
text-transform: uppercase;

z-index: 0;
height: 10%;
width: 25%;

}


.side-drawer {
background: linear-gradient(to bottom,  rgba(132,36,12,1) 0%,rgba(132,36,12,0.9) 44%,rgba(132,36,12,0.97) 99%,rgba(132,36,12,0.97) 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d984240c', endColorstr='#f784240c',GradientType=0 ); 
}

`;
export default SideDrawer;
