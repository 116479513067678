import React from 'react';
import {Link} from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components';
import DrawerToggleButton from '../DrowerToggleButton/DrawerToggleButton.component';
import MainMenu from '../MainMenu';


const Toolbar = (props) => (
    <HeaderWrapper>
        <nav className='toolbar-navigation'>
            <div className='toolbar-toggle-button'>
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            <div className='toolbar-logo'>
                <Link to='/'>
                    Канцона
                </Link>
            </div>

            <div className='spacer' />

            <div className='toolbar-navigation-items'>
                <ul>
                    {MainMenu.map((menu) => (
                        <li key={menu.title}>
                            <Link style={{ textDecoration: 'none',letterSpacing: '3px' }} to={menu.linkUrl}>
                                {menu.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    </HeaderWrapper>

);

const HeaderWrapper = styled.header`
    position: fixed;
    width: 100%;
    background: rgba(132, 36, 12, 0.9);
    height: 50px;
    top: 0;
    left: 0;
    z-index: 8000;
    border-bottom: 1px solid #83240C;

.toolbar-logo {
    margin-left: 1rem;
}
.toolbar-navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 3rem;
}

.toolbar-logo a {
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
}
.toolbar-logo a:hover,
.toolbar-logo a:active {
    color: white;
}

.spacer {
    flex: 1;
}

.toolbar-navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.toolbar-navigation-items li {
    padding: 0 0.5rem;
}

.toolbar-navigation-items a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: small;
    transition: color 0.2s ease;
}
.toolbar-navigation-items a:hover,
.toolbar-navigation-items a:active {
  color: var(--hovercolor);
}

@media (max-width: 799px) {
    .toolbar-navigation-items {
        display: none;
    }
    .toolbar-navigation {
    }
    .toolbar-logo a {
        /* display: inline-block; */
        position: absolute;
        right:0%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

}

@media (min-width: 800px) {
    .toolbar-toggle-button {
        display: none;
    }
}

`


export default Toolbar;
