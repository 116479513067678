import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import {
  FiMapPin,
  FiPhone,
  FiMail,
  FiFacebook,
  FiInstagram,
} from "react-icons/fi"

const Footer = ({ className }) => {
  const Site = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          address
          phone
          phoneSeparate
          email
          social_links {
            label
            url
          }
        }
      }
    }
  `)
  const info = Site.site.siteMetadata
  const SocialsLinks = Site.site.siteMetadata.social_links
  return (
    <footer className={className}>
      <article className="address">
        <FiMapPin style={{ textDecoration: "none" }} />
        <p>{info.address}</p>
      </article>
      <article className="contact-card">
        <ul className="contact">
          <li className="react-icon">
            <FiPhone />
          </li>
          <li>
            <a href={`tel:${info.phone}`}>{info.phoneSeparate}</a>
          </li>
        </ul>
        <ul className="contact">
          <li className="react-icon">
            <FiMail />
          </li>

          <li>
            <a href={`mailto:${info.email}`}>{info.email}</a>
          </li>
        </ul>
      </article>
      <article className="copyright">
        <p>
          <span>&copy; КАНЦОНА {new Date().getFullYear()} </span>
        </p>
        <ul className="icons">
          {SocialsLinks.map(SingleSocial => (
            <li key={SingleSocial.label}>
              <a
                href={SingleSocial.url}
                target="_blank"
                rel="noreferrer"
                className="circle-icon"
              >
                {SingleSocial.label === "Facebook" ? (
                  <FiFacebook />
                ) : SingleSocial.label === "Instagram" ? (
                  <FiInstagram />
                ) : (
                  <FiPhone />
                )}
              </a>
            </li>
          ))}
        </ul>
      </article>
    </footer>
  )
}

export default styled(Footer)`
  padding-top: 4rem;
  border-top: 5px solid var(--maincolor);
  background: black;

  .address {
    margin: 3rem 0;
    text-align: center;
    color: white;
    font-size: 2rem;
    p {
      color: lightgrey;
    }
  }

  .contact-card {
    margin: 2rem 0;
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 800px) {
    .contact-card {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .contact {
    /* margin: 0.61rem auto; */
    text-align: center;
    font-size: 1.52rem;
  }
  .react-icon {
    font-size: 3rem;
    color: white;
  }

  .copyright {
    background: #222;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .copyright p {
    color: grey;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  }

  .icons {
    padding: 1rem 0;
    display: flex;
  }

  .circle-icon {
    text-decoration: none;
    text-align: center;
    margin-right: 0.5rem;
    font-size: 20px;
    line-height: 40px;
    display: inline-block;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: grey;
    cursor: pointer;
    &:active,
    &:focus,
    &:hover {
      background-color: var(--maincolor);
      color: white;
    }
  }
`
