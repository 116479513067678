import React from 'react';
import styled from 'styled-components';

const drawerToggleButton = (props) => (
    <DrawerToggleButtonWrapper onClick={props.click}>
        <div className='toggle-button-line' />
        <div className='toggle-button-line' />
        <div className='toggle-button-line' />
    </DrawerToggleButtonWrapper>
);

const DrawerToggleButtonWrapper = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;

&:focus {
    outline: none;
}

.toggle-button-line {
    width: 30px;
    height: 2px;
    background: white;
}


`;


export default drawerToggleButton;
