import React from "react"
import Navbar from '../navbar/CustomNavbar/CustomNavbar'
import Footer from "../footercomponent/Footer"

import './reset.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.styles.css"

const Layout = ({ children }) => {
  return (
    <>
      <Navbar/>
      <main>{children}</main>
      <Footer />
    </>
  )
}
export default Layout
