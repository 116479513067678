
import React from "react"
import Layout from "./src/components/layouts/layout/Layout"
import { OrderProvider } from "./src/components/layouts/OrderContext"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <OrderProvider>{element}</OrderProvider>
}
