const MainMenu = [
    {
        title: 'Канцона',
        linkUrl: '/',
    },

    {
        title: 'Настани',
        linkUrl: '/services',
    },
    {
        title: 'Мени',
        linkUrl: '/menu',
    },
   
    {
        title: 'За Нас',
        linkUrl: '/about',
    },
];

export default MainMenu;
